import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/_home": [3],
		"/aa-offer": [4],
		"/activate-device": [5],
		"/assessment-purchase": [6],
		"/download-app": [7],
		"/email-preferences": [8],
		"/expired": [9],
		"/get-started": [10],
		"/inside-pw": [11],
		"/lg-tv": [12],
		"/login": [13],
		"/logout": [14],
		"/prospect-register": [15],
		"/purchase-gift": [16],
		"/reactivation": [17],
		"/redeem": [18],
		"/reset-password": [19],
		"/subscription": [20],
		"/subscription/offer/[offer_hash]": [21],
		"/terminate": [22],
		"/upgrade": [23]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.svelte';