import * as Sentry from '@sentry/sveltekit';
import { hotjarInit } from './lib/utils/hotjar';

const RELEASE = `abc-mkt-web-classic@${__APP_VERSION__}`; // eslint-disable-line

hotjarInit();

const getEnvironment = () => {
	if (window.location.hostname === 'www.abcmouse.com') return 'production';
	if (window.location.hostname === 'stage.abcmouse.com') return 'staging';
	return 'development';
};
const environment = getEnvironment();

const IGNORE_ERRORS = [
  'User Cancelled',
  'UserCancelled',
  /user cancelled/i,
  /The email or password you have entered is incorrect/i
];

Sentry.init({
	dsn: 'https://301f36138b8fb5eadb575346186d3219@o968153.ingest.us.sentry.io/4507946504421376',
	tracesSampleRate: 0.25,
	environment,
	release: RELEASE,
	// replaysSessionSampleRate: 0.1,
	// replaysOnErrorSampleRate: 1,
	normalizeDepth: 6,
	// integrations: [
	//   Sentry.replayIntegration()
	// ],
	ignoreErrors: IGNORE_ERRORS,
	beforeSend(event) {
		// Ignore errors in localhost, log to console
		if (window.location.hostname === 'localhost') {
			console.log('Sentry Error Event:', event);
			return null;
		}

		const frames = event?.exception?.values?.[0]?.stacktrace?.frames;

		if (!frames || frames.length === 0) {
			return event; // no stack frames — let it go through
		}

		// Exclude errors generated from external scripts
		const hasExternalFilename = frames.some((frame) => {
			const filename = frame.filename || '';
			return filename !== '<anonymous>' && !filename.includes('abcmouse.com');
		});

    if (hasExternalFilename) {
      return null;
    }

		// Additional check for ignored errors
		if (event.exception && event.exception.values) {
			const errorMessage = event.exception.values[0]?.value;
			if (
				IGNORE_ERRORS.some((ignored) =>
					typeof ignored === 'string' ? errorMessage?.includes(ignored) : ignored.test(errorMessage)
				)
			) {
				return null;
			}
		}

		return event;
	}
});

export const handleError = Sentry.handleErrorWithSentry();
